import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import classNames from "classnames";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { companyActions, quoteActions, amlClauseActions } from "../../../../../actions";
import { bindActionCreators } from "redux";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import SnackBar from "../../../../../components/SnackBar";
import LoadingPopupNbstp from "../../../../../components/LoadingPopupNbstp";
import PremiumAmount from "../components/PremiumAmount";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import Typography from "@mui/material/Typography";
import ENUMS from "../../../../../utils/Enums";
import SubmitQuoteForm from "../containers/SubmitDoc/SubmitQuoteForm";
import SimpleMessageDialog from "../../../../../components/SimpleMessageDialog";
import config from "../../../../../config/config";
import Util from "../../../../../utils/Util";

class ActionToolbarStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyFormValidationSnackbarOpen: false,
      snackBarErrorShow: false,
      emailDialogOpen: false,
      submitDialogOpen: false,
      triggerLoadingPopup: false,
      activationDateError: false,
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleCompanyFormValidationSnackbarClick = () => {
    this.setState({ companyFormValidationSnackbarOpen: true });
  };

  handleCompanyFormValidationSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ companyFormValidationSnackbarOpen: false });
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleEmailDialogClose = () => {
    this.setState({ emailDialogOpen: false });
  };

  handleClickSave = (isStep3 = false, hasEdits = false) => {
    //only do save on step 3 *AS STEP 4 IS ASYNC*
    isStep3
      ? this.props.save(hasEdits ? ENUMS.STAGES.EMPLOYEE : this.props.quote.stages).then(response => {
          this.setState({ snackBarErrorShow: true });
        })
      : this.setState({ snackBarErrorShow: true });
  };

  handleSubmit = () => {
    this.props.submitCompanyDetailForm();
    this.props.submitAmlClauseForm();
    if (!this.props.form["company-detail"].syncErrors) {
      //if exist update
      if (this.props.company.details.id)
        this.props.companyAction.putCompanyDetail({
          quoteId: this.props.quote.id,
          details: this.props.company.details
        });
      else
        this.props.companyAction.postCompanyDetail({
          quoteId: this.props.quote.id,
          details: this.props.company.details
        });
      this.props.handlerNextStep(3);
    } else {
    }
  };

  handleEmailDialogClickOpen = () => {
    if (!quoteActions.isValid(this.props.quote)) {
      this.handleDependantSnackbarOpen();
      return;
    }
    this.setState({ emailDialogOpen: true });
  };

  renderFormatted_activation_date = () => {
    const { req_effective_date } = this.props.quote;
    if (req_effective_date) return moment(this.props.quote.req_effective_date, "DD-MM-YYYY").format("DD - MMM - YYYY");

    return "Not Selected";
  };

  isActivationDateInPass = () => {
    let activationDate = this.props.quote.req_effective_date
      ? moment(this.props.quote.req_effective_date, "DD/MM/YYYY").toDate()
      : null;
    let today = new Date().setHours(0, 0, 0, 0);
    return activationDate < today;
  };

  render_actual_amount = () => {
    const { quote, intl } = this.props;
    const { premiums } = quote;
    const currencySymbol = "S$";
    const total = premiums.actual.total || 0;
    return `${currencySymbol} ${intl.formatNumber(total)}`;
  };

  handleSubmitPolicy = () => {
    this.setState({ submitDialogOpen: false, triggerLoadingPopup: true });
    this.props.submitPolicy({});
  };

  handleSubmitPolicyOpen = () => {
    if (this.isActivationDateInPass()) {
      this.setState({ activationDateError: true });
    } else {
      this.props.save(ENUMS.STAGES.SUBMISSION).then(() => {
        this.setState({ submitDialogOpen: true });
      });
    }
  };

  handleSubmitPolicyClose = () => {
    this.setState({ submitDialogOpen: false });
  };

  handleDependantSnackbarClose = () => {
    this.props.dependantSnackbarToggle(false);
  };

  handleSubmittedPolicyClose = () => {
    this.props.closeSubmitPolicyPopup({});
    //redirect to save quote
    this.props.history.push("/auth/sp/savedQuotes");
  };

  handleSubmitPolicySnackbarClose = () => {
    this.props.closeSubmitPolicySnackbar({});
  };

  handleFinishSubmission = () => {
    this.setState({ triggerLoadingPopup: false });
  };

  getSubmitErrors = () => {
    return this.props.quote.submittedErrorMsg !== null &&
      this.props.quote.submittedErrorMsg.includes(ENUMS.ERROR_PREFIX.IBMCM)
      ? this.props.intl.formatMessage({ id: error.msg.ibmcm.submission })
      : this.props.quote.submittedErrorMsg;
  };

  render() {
    const { classes, activeStep, handlerNextStep, intl, company, quote, document, valid, hasEdits } = this.props;
    let readonly = this.props.quote.readOnly;

    const ACCEPTANCE_FILE_TYPE = ENUMS.FILE_TYPE.ACCEPTANCE_FILE_TYPE;

    const acceptanceLetterFiles = document && document?.files?.find(file => file.file_type === ACCEPTANCE_FILE_TYPE);

    let saveButtonDisabled =
      readonly ||
      !valid ||
      (quote && quote?.stages !== ENUMS.STAGES.PROPOSAL && quote?.stages !== ENUMS.STAGES.SUBMISSION) ||
      quote?.valid_draft === false;

    if (acceptanceLetterFiles) {
      if (
        document &&
        document.hasSignedProposal &&
        Util.isSubmitNbstpDisabled(
          Util.convertEpochToDate(acceptanceLetterFiles?.created),
          config.disableSubmitSignDate,
          config.dateStartDisabling
        )
      ) {
        saveButtonDisabled = true;
      }
    }

    return (
      <Toolbar className={classes.container}>
        {activeStep === 3 ? (
          <>
            <div className={classes.actions}>
              <Dialog
                open={this.state.submitDialogOpen}
                onClose={this.handleSubmitPolicyClose}
                aria-labelledby="email-detail-dialog"
              >
                <DialogTitle className={classes.dialogTitle}>Submit For Review</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <Typography variant="subheading">
                    Please verify the details below are correct before submitting.
                  </Typography>
                  <br />
                  <SubmitQuoteForm
                    companyName={company.details.name}
                    activationDate={this.renderFormatted_activation_date()}
                    actualAmount={this.render_actual_amount()}
                  />
                </DialogContent>
                <DialogActions classes={{ root: classes.centerButtons }}>
                  <Button
                    onClick={this.handleSubmitPolicyClose}
                    variant="raised"
                    className={classNames(classes.button, classes.cancelButton)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.handleSubmitPolicy}
                    variant="raised"
                    color="primary"
                    className={classNames(classes.button, classes.okButton)}
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              <SimpleMessageDialog
                type="success"
                name="submit-for-activation"
                title="Submission Successful"
                isHtml={true}
                description={`<span style="font-size: 14px;">Thank you for your submission on PruWorks. <br/><br/>Your application shall be reviewed, and we will be in touch with you if we have any queries.<br/><br/><b>Note: Please submit Employee/Dependent Health Declaration Form to Medical Underwriter if there is GTL/GCCA Plan 1 and any GPCG plans in your application.</b></span>`}
                closeButtonText="OK"
                closeHandler={this.handleSubmittedPolicyClose}
                openHandler={this.handleFinishSubmission}
                open={this.props.quote.showSuccessDialog}
              />
            </div>
            <div className={classes.leftActions}>
              <Button
                variant="raised"
                color="inherit"
                className={classNames(classes.button, classes.buttonSave)}
                onClick={() => this.handleClickSave(true)}
                disabled={
                  readonly ||
                  (this.props.form &&
                    this.props.form["company-detail"] &&
                    this.props.form["company-detail"].syncErrors !== undefined)
                }
              >
                {intl.formatMessage({ id: "actiontoolbar.button.save" })}
              </Button>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.actions}>
              <PremiumAmount showEstimate={false} />
              <Button
                variant="raised"
                disabled={saveButtonDisabled}
                className={classNames(classes.button, saveButtonDisabled ? classes.buttonDisabled : null)}
                onClick={this.handleSubmitPolicyOpen}
              >
                {intl.formatMessage({ id: "actiontoolbar.button.submit" })}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.leftActions}>
              <Button
                variant="raised"
                color="inherit"
                className={classNames(classes.button, classes.buttonSave)}
                onClick={() => this.handleClickSave(true, hasEdits)}
                disabled={
                  readonly ||
                  (document && document.hasSignedProposal) ||
                  (this.props.form &&
                    this.props.form["company-detail"] &&
                    this.props.form["company-detail"].syncErrors !== undefined)
                }
              >
                {intl.formatMessage({ id: "actiontoolbar.button.save" })}
              </Button>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.actions}>
              <PremiumAmount showEstimate={false} />
              <Button
                variant="raised"
                color="primary"
                className={classes.button}
                onClick={handlerNextStep(3)}
                disabled={
                  this.props.form &&
                  this.props.form["company-detail"] &&
                  this.props.form["company-detail"].syncErrors !== undefined
                }
              >
                {intl.formatMessage({ id: "actiontoolbar.button.continue" })}
              </Button>
            </div>
          </>
        )}
        <SnackBar
          type="error"
          onClose={this.handleCompanyFormValidationSnackbarClose}
          open={this.state.companyFormValidationSnackbarOpen}
          message="Form Invalid"
        />
        <SnackBar
          type="error"
          onClose={this.handleDependantSnackbarClose}
          open={this.props.quote.dependantSnackbarFlag}
          message="Please make sure that all steps have been completed in the previous screens before sending the document pack."
        />
        <SnackBar
          type="error"
          onClose={this.handleSubmitPolicySnackbarClose}
          onOpen={this.handleFinishSubmission}
          open={this.props.quote.showSnackBarError}
          message={this.getSubmitErrors()}
        />
        <SnackBar
          type="error"
          onClose={() => {
            this.setState({ activationDateError: false });
          }}
          open={this.state.activationDateError}
          message={"The coverage start date cannot be in the past."}
        />
        <SnackBar
          onClose={this.handleSubmitPolicySnackbarClose}
          open={this.props.quote.showSnackBarSuccess}
          message={intl.formatMessage({ id: "quote.generate.success" })}
        />
        <SnackBar
          type="success"
          open={this.state.snackBarErrorShow}
          onClose={() => this.setState({ snackBarErrorShow: false })}
          message="The quote has been saved"
        />
        <LoadingPopupNbstp
          title="popup.loading.submitQuote.title"
          description="popup.loading.submitQuote.description"
          subDescription="popup.loading.submitQuote.subDescription"
          name="send-document-loading"
          open={this.state.triggerLoadingPopup}
        />
      </Toolbar>
    );
  }
}

const styles = theme => ({
  container: {
    justifyContent: "flex-end",
    borderTop: `solid 1px ${theme.colors.blackScale.black70}`,
    ...theme.extended.minHeight(55)
  },
  popperClose: {
    pointerEvents: "none"
  },
  dialogTitle: {
    textAlign: "left",
    color: "#78ebb7"
  },
  dialogContent: {
    minWidth: "350px"
  },
  dialogContentText: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "20px"
  },
  centerLayout: {
    display: "flex",
    justifyContent: "center"
  },
  actions: {
    display: "flex",
    flexDirection: "row"
  },
  cancelButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid ${theme.colors.blackScale.black50}`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500
  },
  centerButtons: {
    justifyContent: "center"
  },
  button: {
    marginLeft: 20,
    minWidth: 150
  },
  buttonSave: {
    backgroundColor: theme.colors.secondary.white
  },
  buttonDisabled: {
    backgroundColor: theme.colors.primary.darkGrey
  },
  leftActions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: -20
  }
});

function mapStateToProps(state, prop) {
  return {
    form: state.form,
    company: state.company,
    quote: state.quote,
    document: state.document,
    dependantSnackbarFlag: state.quote.dependantSnackbarFlag
  };
}

function mapDispatchToProps(dispatch) {
  return {
    save: (...payload) => dispatch(quoteActions.save(...payload)),
    dependantSnackbarToggle: (...payload) => dispatch(quoteActions.dependantSnackbarToggle(...payload)),
    submitPolicy: (...payload) => dispatch(quoteActions.submitPolicy(...payload)),
    submitGenerateQuote: (...payload) => dispatch(quoteActions.submitGenerateQuote(...payload)),
    closeSubmitPolicyPopup: (...payload) => dispatch(quoteActions.closeSubmitPolicyPopup(...payload)),
    closeSubmitPolicySnackbar: (...payload) => dispatch(quoteActions.closeSubmitPolicySnackbar(...payload)),
    companyAction: bindActionCreators(companyActions, dispatch),
    amlAction: bindActionCreators(amlClauseActions, dispatch),
    submitCompanyDetailForm: () => {
      dispatch(submit("company-detail"));
    },
    submitAmlClauseForm: () => {
      dispatch(submit("aml-clauses-form"));
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(ActionToolbarStep2)))
);
